import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import FooterOne from "../components/footer/FooterOne";

import Accordion from 'react-bootstrap/Accordion';

const OurMission = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Our Mission' }
    ];
  return (
   <>  
   
   <div className=''>

<HeaderOne />
<Breadcrumb title="Our Mission" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">

            <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> Our Mission </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                        <p className="disc"> To offer effective services without relinquishing quality and to fulfil the needs of the client by developing their business, holistically. We intend to help the business accomplish a remarkable exhibition online through our imaginative approach. 
                        </p>
                    
                    </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}






{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >

                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        Neque partrient nascetur facilisis suscipit ridiculus agna
                                        lobortis imperdiet vivamus est aliquam euismod nector quam
                                        convallis ornare justo service lifereu visionary sources
                                        unleash online businesss solutions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span> What should i included my personal
                                    details?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span> Where i can find my business growth
                                    result?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>03. </span> Did you get any business consultant?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


{/* customers feed back area start */}
<div className="rts-customer-feedback-area rts-section-gap bg-customer-feedback">
    <div className="container">
        <div className="row">
            <div className="rts-title-area feedback team text-center">
                <p className="pre-title">Feedbacks</p>
                <h2 className="title">Customer Feedbacks</h2>
            </div>
        </div>
        <div className="row g-5 mt--20">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/02.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/03.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* customers feed back area end */}


<FooterOne />

</div>


   
   </>
  )
}

export default OurMission