import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import FooterOne from "../components/footer/FooterOne";
import Accordion from 'react-bootstrap/Accordion';

const AboutUs = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'About Us' }
    ];
  return (
   <>   
   
   <div className=''>

<HeaderOne />
<Breadcrumb title="About Us" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">

<div className='col-lg-12 col-md-12 col-12'>  



</div>

            <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> About us </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                        <p className="disc">
                        Greetings, Welcome to our website! Today, I'm excited to share with you the story of Planetronix Consulting Private Limited, a company that has been a shining example of innovation and reliability for over two decades. Established in 2001, Planetronix has evolved into a dependable ally across a wide range of critical sectors. We're discussing sectors such as Military and Defence, Aerospace, Government and Public Sector Units, Indian Railways, and Healthcare. This extensive coverage highlights their versatility and expertise.
                        </p>
                        <p className="disc">    Planetronix is a homegrown manufacturer of bespoke, rugged systems, and they also serve as a partner for numerous international Original Equipment Manufacturers (OEMs) for sales, support, and spare parts management in India.  </p>
                        <p className="disc">  Planetronix boasts an ISO 9001-2015 certification, which is more than just a label – it underscores their dedication to excellence and continuous enhancement. In sectors like defence and aerospace, it's imperative to have a partner who consistently excels. Planetronix excels in this area. Their product portfolio and services are crafted to address the specific requirements of each sector they operate in. For the military and defence industry, they offer state-of-the-art technology and robust solutions that boost operational efficiency and security. In the aerospace sector, their focus is on precision and dependability. For government and public sector entities, Planetronix ensures their solutions are both effective and sustainable. Let's not overlook their contribution to the Indian Railways, where they play a crucial role in the supply and maintenance of top-tier equipment, facilitating safe and efficient transportation – the cornerstone of our nation's connectivity. In the healthcare sector, Planetronix introduces innovative solutions that improve patient care and streamline operations. However, what truly distinguishes Planetronix is their relentless commitment to customer satisfaction. They engage closely with their clients to understand their unique needs and challenges, delivering customized solutions that deliver tangible results.    </p>
                        <p className="disc">  So, whether you're in the defence sector seeking dependable defence solutions, or in the healthcare industry aiming to enhance patient care, Planetronix Consulting Private Limited is your go-to partner. With over two decades of experience and a steadfast dedication to quality, they are a partner you can rely on. Thank you for joining us today to learn more about Planetronix. Remember, in an era where reliability and innovation are paramount, Planetronix remains a leader. Until next time, stay informed and stay ahead.    </p>
                    
                    </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}






{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >

                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body">
                                        Neque partrient nascetur facilisis suscipit ridiculus agna
                                        lobortis imperdiet vivamus est aliquam euismod nector quam
                                        convallis ornare justo service lifereu visionary sources
                                        unleash online businesss solutions
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span> What should i included my personal
                                    details?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span> Where i can find my business growth
                                    result?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>03. </span> Did you get any business consultant?</Accordion.Header>
                                <Accordion.Body>
                                    Neque partrient nascetur facilisis suscipit ridiculus agna
                                    lobortis imperdiet vivamus est aliquam euismod nector quam
                                    convallis ornare justo service lifereu visionary sources
                                    unleash online businesss solutions
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


{/* customers feed back area start */}
<div className="rts-customer-feedback-area rts-section-gap bg-customer-feedback">
    <div className="container">
        <div className="row">
            <div className="rts-title-area feedback team text-center">
                <p className="pre-title">Feedbacks</p>
                <h2 className="title">Customer Feedbacks</h2>
            </div>
        </div>
        <div className="row g-5 mt--20">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/02.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/03.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* customers feed back area end */}


<FooterOne />

</div>

   
   </>
  )
}

export default AboutUs